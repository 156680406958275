import React, { useState, createRef, useEffect } from "react"
import { Link } from "gatsby"
import { Map, TileLayer, Marker } from 'react-leaflet'
import './leafletmap.scss'
import pin from '../images/icon/location.svg'
import pinprod from '../images/icon/marker.svg'
import pinSelect from '../images/icon/locationselected.svg'
import producteurIcon from '../images/icon/courrier-de-livraison.svg'
import groceriesIcon from '../images/icon/paper-bag.svg'
import barnIcon from '../images/icon/barn.svg'
import calendarIcon from '../images/icon/calendar.svg'
import { graphql, useStaticQuery } from "gatsby"
import { setZone, setPickupPoint } from "../actions/panier"
import { useDispatch, useSelector } from "react-redux"
import MarkerClusterGroup from 'react-leaflet-markercluster';

export const LeafletMap = props => {
  const dispatch = useDispatch()

  const data = useStaticQuery(graphql`
    query dataIndex {
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_isprod
            latitude
            longitude
            group_address
            group_ville
            group_horaire
            group_person
            group_zone_id
          }
        }
      }
      allMysqlZone {
        edges {
          node {
            zone_info
            zone_id
          }
        }
      }
    }
  `)

  const [position, setPosition] = useState({
    latlng: {
      lat: 46.939158,
      lng: 6.033106
    },
    zoom: 7,
  })

  const [periodicite] = useState({
    2: "toutes les deux semaines",
    11: "toutes les deux semaines",
    12: "toutes les semaines",
    16: "toutes les deux semaines",
    21: "toutes les semaines",
  })

  const [markerRef] = useState(createRef())
  const [markerSelectRef] = useState(createRef())
  const [mapRef] = useState(createRef())
  const panier = useSelector(state => state.panierR)
  const [displayPanel, setDisplayPanel] = useState(false)
  const [adresse, setAdresse] = useState("")
  const [ville, setVille] = useState("")
  const [horaire, setHoraire] = useState("")
  const [local, setLocal] = useState("")
  const [zone, setZoneId] = useState("")
  const [groupe, setGroupe] = useState("")
  const [producteur, setProducteur] = useState("")
  const [producteurList, setProducteurList] = useState(props.producteur)

  const markersPin = props.producteur && props.producteur !== "" && props.producteur !== undefined ? 
    data.allMysqlGroupe.edges.filter(mark => props.producteur.includes(mark.node.group_zone_id)) : data.allMysqlGroupe.edges

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (props.positionZoom && props.positionZoom !== "") {
        setPosition(props.positionZoom)
        setDisplayPanel(false)
      }
    }
  }, [props.positionZoom])

  useEffect(() => {
    if (typeof window !== "undefined" && JSON.stringify(props.producteur) !== JSON.stringify(producteurList)) {
      setProducteurList(props.producteur);
      setPosition({
        latlng: {
          lat: 46.939158,
          lng: 6.033106
        },
        zoom: 7,
      });
      setTimeout(() => { /* workaround en attendant de trouver comment pouvoir forcer proprement rezoom */
        setPosition({
          latlng: {
            lat: 46.939158,
            lng: 6.033106
          },
          zoom: 8,
        });
      })
      setDisplayPanel(false);
    }
  }, [props.producteur, producteurList])

  useEffect(() => {
    if (typeof window !== "undefined") {
      setTimeout(() => {
        if (markerSelectRef && markerSelectRef.current !== null && markerSelectRef.current !== undefined) {
          markerSelectRef.current.props.onClick()
        }
      }, 100);
    }
  }, [markerSelectRef])

  const GetProducteur = (zoneA) => {
    const thisZone = data.allMysqlZone.edges.find(zoneI => parseInt(zoneI.node.zone_id) === parseInt(zoneA))
    return thisZone ? thisZone.node.zone_info : null
  }

  const DispatchPickup = () => {
    dispatch(setPickupPoint(groupe))
    dispatch(setZone(zone))
  }

  const L = require('leaflet');

  const myIcon = L.icon({
    iconUrl: pin,
    iconSize: [70, 70],
    /*iconAnchor: [32, 64], Pour décaler l'icon  */
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });

  const myIconProd = L.icon({
    iconUrl: pinprod,
    iconSize: [70, 70],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });

  const myIconSelected = L.icon({
    iconUrl: pinSelect,
    iconSize: [70, 70],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    className: "zindexmax",
  });

  return (
    <div className="markercluster-map">
      <div className="map-slider" style={{ display: displayPanel === false ? "none" : "block" }}>
        <div className="remove" role="button" tabIndex="-2" onKeyPress={() => { }} onClick={() => { setDisplayPanel(false) }}> </div>
        <div className="slider-header">
          <p className="name has-text-centered dreamland is-size-3">{ville.toUpperCase()}</p>
        </div>
        <div className="slider-content">
          <div className="action mt-4">
            <div className="has-text-centered">
              <Link to={`/producteur/${zone}-${producteur}/produits`} onClick={() => DispatchPickup()} className="button is-success is-light is-large dreamland is-size-3">
                <img src={groceriesIcon} style={{ width: 40, height: 40, position: 'relative', top: 12, marginRight: 15 }} alt="Produits" />
                  <span>Voir les produits</span>&nbsp;<span className="is-hidden-mobile">disponibles</span>
                </Link>
            </div>
            {props.accueil !== true && props.producteur && props.producteur !== "" ? "" :
              <div className="has-text-centered mt-4">
                <Link to={zone === 2 || zone === 11 ? `/producteur/${producteur}?${zone}` : `/producteur/${producteur}`} onClick={() => { DispatchPickup() }} className="button is-primary is-light is-large dreamland is-size-3">
                  <img src={producteurIcon} style={{ width: 50, height: 50, position: 'relative', top: 12, marginRight: 10 }} alt="Producteur" />
                    Voir le producteur
                  </Link>
              </div>
            }
          </div>
          <div className="mt-4" style={{ background: "#fffaaa", padding: "1rem" }}>
            <h3 className="title has-text-centered is-size-4 almond" style={{ color: "#7e421e", paddingBottom: '0.4rem', borderBottom: "1px dotted #7e421e" }}>
              Retrait des produits
              </h3>
            <div className="columns is-mobile">
              <div className="column is-one-fifth has-text-right">
                <img src={barnIcon} style={{ width: 40, height: 40 }} alt="Point de dépôt" />
              </div>
              <div className="column">
                <p>{local !== "" && local !== null ? "Chez " + local : ""}</p>
                <p>{adresse}</p>
                <p>{ville.toUpperCase()}</p>
              </div>
            </div>

            <div className="columns is-mobile">
              <div className="column is-one-fifth has-text-right">
                <img src={calendarIcon} style={{ width: 40, height: 40 }} alt="Calendrier" />
              </div>
              <div className="column">
                <p>Livraison {periodicite[zone]}</p>
                <p>{horaire}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Map
        center={position.latlng}
        zoom={position.zoom}
        ref={mapRef}
        minZoom={5}
        zoomDelta={0.5}
      >
        <TileLayer
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a>"
        />
        <MarkerClusterGroup>
          {markersPin.map(({ key, ...group }) => (
            <Marker
              ref={panier.pickupPoint !== null && parseInt(panier.pickupPoint) === parseInt(group.node.group_id) ? markerSelectRef : markerRef}
              icon={panier.pickupPoint !== null && parseInt(panier.pickupPoint) === parseInt(group.node.group_id) ? myIconSelected : props.accueil !== true && props.producteur && props.producteur !== "" && parseInt(group.node.group_isprod) === 1 ? myIconProd : myIcon}
              onClick={() => {
                setAdresse(group.node.group_address)
                setVille(group.node.group_ville)
                setHoraire(group.node.group_horaire)
                setLocal(group.node.group_person)
                setZoneId(group.node.group_zone_id)
                setGroupe(group.node.group_id)
                setDisplayPanel(true)
                setPosition({ ...position, latlng: { lat: group.node.latitude, lng: group.node.longitude }, zoom: 13 })
                setProducteur(GetProducteur(group.node.group_zone_id))
              }}
              key={group.node.group_id} position={[group.node.latitude, group.node.longitude]}>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </Map>
    </div>
  );

}

export default LeafletMap
